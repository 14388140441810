'use client';

import { useState } from 'react';

import Button from '@/app/components/base/ui/Button';

import Icon from '../v3/Icon';
import { FlagsList } from './flags-list';
import { IllustrationsList } from './illustrations-list';
import { ListIcons } from './list-icons';

const itens = [
  { description: 'Ícones', component: ListIcons },
  { description: 'Ilustrações', component: IllustrationsList },
  { description: 'Flags', component: FlagsList }, // TODO(@gabrieldissotti) adcionar os previews de componentes dinâmicos aqui;
];

export const DevToolsPanel = () => {
  const [isOpen, toggleOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(itens[0].description);

  const activePlugin = itens.find(item => item.description === activeTab);

  const Plugin = activePlugin?.component as React.ElementType;

  return (
    <div className={`fixed bottom-0 left-0 z-50 p-16 w-auto overflow-hidden`}>
      {!isOpen && (
        <Button onClick={() => toggleOpen(true)} sectionReference="Dev Tols">
          Assets
        </Button>
      )}
      {isOpen && (
        <div className="p-16 w-full h-full bg-display-200 rounded-large">
          <div className="inline-flex gap-16 mb-16 w-full">
            {itens.map(({ description }, index) => (
              <Button
                key={index}
                color={activeTab === description ? 'primary' : 'secondary'}
                onClick={() => setActiveTab(description)}
                sectionReference="devtools"
              >
                {description.toUpperCase()}
              </Button>
            ))}

            <div className="flex items-start">
              <button
                type="button"
                onClick={() => toggleOpen(false)}
                data-testid="close-modal"
                className="absolute top-[30] right-20 ml-auto w-40 h-40 bg-transparent rounded-full"
              >
                <Icon name="close" className="text-display-900 fill-current" />
              </button>
            </div>
          </div>
          {Plugin && <Plugin />}
        </div>
      )}
    </div>
  );
};
