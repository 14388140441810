'use client';

import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import QRCodeImage from 'react-qr-code';

import Button from '@/app/components/base/ui/Button';
import Icon from '@/components/v3/Icon';
import LINKS from '@/constants/links';
import { useAccountExperiment } from '@/hooks/useAccountExperiment';

export type StepStatus = 'error' | 'completed' | 'next' | 'clock';

export interface Step {
  title: string;
  description: string | JSX.Element;
  status: StepStatus;
}

interface StatusTrackeProps {
  steps: Step[];
}

export function StatusTracker({ steps }: StatusTrackeProps) {
  const pathName = usePathname();

  const { shouldEnterWebAccountOpeningFlow } = useAccountExperiment();
  const appsflyer = pathName?.includes('abra-sua-conta')
    ? LINKS.APP_LINK_APPSFLYER_ACCOUNT_OPPENING_DEFAULT
    : shouldEnterWebAccountOpeningFlow
    ? LINKS.APP_LINK_APPSFLYER_ACCOUNT_OPPENING_TEST
    : LINKS.APP_LINK_APPSFLYER_QR_CODE_CONTA_PJ;

  return (
    <div className="flex flex-col">
      {steps.map(({ title, description, status }, index) => (
        <div key={title} className="flex flex-row gap-12">
          <div className="flex flex-col justify-start items-center h-auto">
            <div className="flex overflow-hidden relative justify-center items-center my-6 w-24 min-w-[24px] h-24 min-h-[24px]">
              {status === 'completed' && (
                <Icon
                  name="check-round"
                  className="w-24 h-auto text-white fill-current"
                />
              )}

              {status === 'error' && (
                <Icon
                  name="close-round"
                  className="w-20 h-auto text-danger-900 fill-current"
                />
              )}

              {status === 'clock' && (
                <Icon
                  name="clock"
                  className="w-[21px] h-auto text-display-800 fill-current"
                />
              )}

              {status === 'next' && (
                <div className="flex justify-center items-center w-24 h-24 rounded-full border-2 border-display-400" />
              )}
            </div>

            {index !== steps.length - 1 && (
              <div
                className={classNames('h-full border-l-2', {
                  'border-stone-500': status === 'completed',
                  'border-display-300': ['next', 'current', 'error'].includes(
                    status,
                  ),
                })}
              />
            )}
          </div>

          <div key={title} className="pb-[26px] mt-6">
            <p
              className={classNames(
                'font-sans text-base font-medium leading-4 ',
                {
                  'text-display-800': status !== 'error',
                  'text-danger-900': status === 'error',
                },
              )}
            >
              {title}
            </p>
            <p className="font-sans text-sm leading-3 text-gray-600">
              {description}
            </p>

            {status === 'clock' && (
              <div className="flex flex-row gap-24 mt-24">
                <div className="hidden lg:flex flex-col gap-24 items-start">
                  <div className="p-16 rounded-lg border border-display-300 border-solid">
                    <QRCodeImage size={115} value={appsflyer} />
                  </div>

                  <p className="font-sans text-sm leading-3 text-left text-display-700">
                    Aponte a câmera do seu celular para a imagem do QR Code e
                    baixe o App Stone
                  </p>
                </div>

                <div className="block lg:hidden w-full">
                  <div className="flex sm:grid flex-wrap sm:grid-cols-2 gap-16 w-full sm:w-max max-w-full">
                    <Button
                      color="primary"
                      className="w-full"
                      linkHref={appsflyer}
                      sectionReference="Falta pouco pra voce abrir sua conta"
                    >
                      Baixe o App
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
