'use client';

import { useRouter } from 'next/navigation';
import { createContext, useCallback, useContext, useState } from 'react';

import DrawerAccountOpening from '@/app/components/sections/OpenAccount/drawer';
import QRCode from '@/components/v3/QRCode';
import LINKS from '@/constants/links';
import { useAccountExperiment } from '@/hooks/useAccountExperiment';

type QRCodeDrawerContextType = {
  toggleDrawer: () => void;
  setDescription: (link: string) => void;
  setTitle: (link: string) => void;
  setQrCodeLink: (link: string) => void;
  qrCodeLink?: string;
};

const QRCodeDrawerContextDefaultValues: QRCodeDrawerContextType = {
  toggleDrawer: () => undefined,
  setQrCodeLink: () => undefined,
  setDescription: () => undefined,
  setTitle: () => undefined,
};

const QRCodeDrawerContext = createContext<QRCodeDrawerContextType>(
  QRCodeDrawerContextDefaultValues,
);

export const useQRCodeDrawer = (): QRCodeDrawerContextType => {
  return useContext(QRCodeDrawerContext);
};

export const QRCodeDrawerProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [qrCodeLink, setQrCodeLink] = useState(
    LINKS.APP_LINK_APPSFLYER_QR_CODE_LINK_PAGAMENTO,
  );
  const [description, setDescription] = useState<string>();
  const [title, setTitle] = useState<string>();
  const {
    shouldEnterWebAccountOpeningFlow,
    shouldEnterWebAccountOpeningFlowControl,
  } = useAccountExperiment();
  const router = useRouter();

  const toggleDrawer = useCallback(() => {
    setIsVisible(value => !value);
  }, [router]);

  return (
    <QRCodeDrawerContext.Provider
      value={{
        toggleDrawer,
        setQrCodeLink,
        setDescription,
        setTitle,
      }}
    >
      <>
        {shouldEnterWebAccountOpeningFlow ? (
          <DrawerAccountOpening isShowing={isVisible} onToggle={toggleDrawer} />
        ) : shouldEnterWebAccountOpeningFlowControl ? (
          <QRCode
            qrCodeLink={LINKS.APP_LINK_APPSFLYER_ACCOUNT_OPPENING_CONTROL}
            isShowing={isVisible}
            onToggle={toggleDrawer}
            description={description}
            title={title}
          />
        ) : (
          <QRCode
            isShowing={isVisible}
            onToggle={toggleDrawer}
            qrCodeLink={qrCodeLink}
            description={description}
            title={title}
          />
        )}

        {children}
      </>
    </QRCodeDrawerContext.Provider>
  );
};
