'use client';

import Button from '@/app/components/base/ui/Button';
import Icon, { IconsNames } from '@/components/v3/Icon';

import { StatusTracker, StepStatus } from '../StatusTracker';

interface Flow {
  title: string;
  description: string;
  icon: IconsNames;
  steps: StepStatus[];
}

interface FeedbackStates {
  account_openning_under_review: Flow;
  account_opening_rejected_due_to_risk: Flow;
}

const defaultSteps = [
  {
    title: 'Te enviamos um e-mail',
    description: (
      <>
        Confira o e-mail que te enviamos e toque em ”criar minha senha de
        acesso”.
        <br />
        Caso já possua uma conta com a gente, você recebera instruções para
        criar uma nova senha.
      </>
    ),
  },
  {
    title: 'Baixe o App Stone',
    description:
      'Após criar sua senha de acesso, complete seu cadastro pelo aplicativo.',
  },
  {
    title: 'Conta aberta',
    description:
      'Valide sua identidade no App Stone e aproveite nossas soluções financeiras.',
  },
];

// place first statuses, if there are more steps, they will be 'next'
const feedbackStates: FeedbackStates = {
  account_openning_under_review: {
    steps: ['completed', 'clock'],
    icon: 'mail-successes',
    title: 'Falta pouco pra você abrir sua conta',
    description:
      'Confira seu e-mail, crie sua senha de acesso e siga com o cadastro pelo aplicativo Stone',
  },
  account_opening_rejected_due_to_risk: {
    steps: ['completed', 'error'],
    icon: 'stonji/card-error',
    title: 'Não foi possível abrir sua conta',
    description:
      'Por favor, atualize a página no seu navegador e tente novamente.',
  },
};

export type AccountFeedbackStates = keyof typeof feedbackStates;

export interface FeedbackProps {
  status: AccountFeedbackStates;
  setStatus: (status: AccountFeedbackStates) => void;
}
export function Feedback({ status, setStatus }: FeedbackProps) {
  const { steps, title, description, icon } = feedbackStates[status];
  return (
    <div className="flex flex-col justify-center items-start w-full h-auto">
      <Icon name={icon} className="mb-16 h-48" />

      <h3 className="mb-4 font-display text-2xl font-bold leading-6">
        {title}
      </h3>

      <p className="mb-[18px]">{description}</p>
      {status === 'account_openning_under_review' ? (
        <StatusTracker
          steps={defaultSteps.map((step, index) => ({
            ...step,
            status: steps[index] || 'next',
          }))}
        />
      ) : (
        <Button
          color="primary"
          className="mt-24 w-full md:w-full"
          sectionReference={title}
          onClick={() => setStatus('' as AccountFeedbackStates)}
        >
          Tentar novamente
        </Button>
      )}
    </div>
  );
}
