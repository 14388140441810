import { datadogRum } from '@datadog/browser-rum';
import packageJson from '../../package.json';

const applicationId = process.env.DATADOG_APP_ID || '';
const clientToken = process.env.DATADOG_CLIENT_TOKEN || '';

datadogRum.init({
  applicationId,
  clientToken,
  site: 'datadoghq.com',
  service: 'stone-site-front-end',
  env: process.env.DD_ENVIRONMENT,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 2,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  version: packageJson.version,
});

datadogRum.setGlobalContext({
  owner: 'tribo-web-stone',
  project: 'stone-site-front-end',
  'cost-center': '860290021',
  support: 'tribo-web-stone',
});

export default function DatadogInit() {
  return null;
}
