import * as Yup from 'yup';

import { isValidCnpj, isValidCpf } from '@/helpers/validateDocument';

const INVALID_EMAILS = new Set([
  'test.com',
  'teste.com',
  'teste.com.br',
  'test@test',
  'teste@teste',
]);

const isInvalidEmail = (email: string) => {
  const domain = email.split('@')[1]?.toLowerCase();
  return domain ? INVALID_EMAILS.has(domain) : false;
};

export const schema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .matches(
      /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?: [A-Za-zÀ-ÖØ-öø-ÿ]+)+$/,
      'Informe nome e sobrenome',
    )
    .required('Este campo é obrigatório!'),

  email: Yup.string()
    .trim()
    .email('Este não é um e-mail válido!')
    .test('not-test-email', 'E-mail não permitido', value =>
      value ? !isInvalidEmail(value) : false,
    )
    .required('Este campo é obrigatório!'),

  cnpj: Yup.string()
    .trim()
    .required('Este campo é obrigatório!')
    .test('valid-cnpj', 'CNPJ não é válido', value => isValidCnpj(value)),

  cpf: Yup.string()
    .trim()
    .required('Este campo é obrigatório!')
    .test('valid-cpf', 'CPF não é válido', value => isValidCpf(value)),
});

export const schemaDrawer = Yup.object().shape({
  fullName_drawer: Yup.string()
    .trim()
    .matches(
      /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?: [A-Za-zÀ-ÖØ-öø-ÿ]+)+$/,
      'Informe nome e sobrenome',
    )
    .required('Este campo é obrigatório!'),

  email_drawer: Yup.string()
    .trim()
    .email('Este não é um e-mail válido!')
    .test('not-test-email', 'E-mail não permitido', value =>
      value ? !isInvalidEmail(value) : false,
    )
    .required('Este campo é obrigatório!'),

  cnpj_drawer: Yup.string()
    .trim()
    .required('Este campo é obrigatório!')
    .test('valid-cnpj', 'CNPJ não é válido', value => isValidCnpj(value || '')),

  cpf_drawer: Yup.string()
    .trim()
    .required('Este campo é obrigatório!')
    .test('valid-cpf', 'CPF não é válido', value => isValidCpf(value || '')),
});
