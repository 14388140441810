'use client';

import { Form as FormFormik, Formik, FormikValues } from 'formik';
import Link from 'next/link';
import { useCallback, useContext, useState } from 'react';

import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';
import { stoneSignUp } from '@/services/stoneSignUp';
import { cnpjMask, cpfMask, dontAllowWhitespaceMask } from '@/utils/masks';

import Button from '../../../base/ui/Button';
import { AccountFeedbackStates } from '../Feedback';
import TextInput from '../FormikTextInput';
import { schema, schemaDrawer } from './schema';

interface FormProps {
  setStatus: (status: AccountFeedbackStates) => void;
  isDrawer?: boolean;
}

export default function Form({ setStatus, isDrawer }: FormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { analytics } = useContext(AmplitudeContext);

  const suffix = isDrawer ? '_drawer' : '';
  const validationSchema = isDrawer ? schemaDrawer : schema;

  const initialValues = {
    [`fullName${suffix}`]: '',
    [`email${suffix}`]: '',
    [`cnpj${suffix}`]: '',
    [`cpf${suffix}`]: '',
  };

  const formatData = useCallback(
    (data: FormikValues) => ({
      fullName: data[`fullName${suffix}`],
      email: data[`email${suffix}`],
      cnpj: data[`cnpj${suffix}`],
      cpf: data[`cpf${suffix}`],
    }),
    [suffix],
  );

  const trackOnBlurFields = async (name: string) => {
    const valueClear = name.replace('_drawer', '');

    analytics?.track({
      event_type: `step ${valueClear} completed`,
      event_properties: {
        name: `step ${valueClear} completed`,
        description: `Evento disparado quando o usuário preenche o ${valueClear}.`,
      },
    });
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
    callback: (name: string) => void,
  ) => {
    const { value, name } = event.target;
    if (value) {
      callback(name);
    }
  };

  const onSubmit = useCallback(
    async (data: FormikValues) => {
      let status = '';
      let statusReference = '';
      try {
        setIsLoading(true);
        await stoneSignUp(formatData(data));
        setStatus('account_openning_under_review');
        status = 'request enviada com sucesso';
        statusReference = 'Falta pouco pra voce abrir sua conta';
      } catch (err) {
        console.error(err);
        setStatus('account_opening_rejected_due_to_risk');
        status = 'erro';
        statusReference = 'Não foi possivel abrir sua conta';
      } finally {
        analytics?.track({
          event_type: 'page viewed stone',
          event_properties: {
            name: 'page viewed stone',
            description: `Evento disparado na visualização da tela`,
            status,
            section_reference: statusReference,
          },
        });
        setIsLoading(false);
      }
    },
    [analytics, formatData, setStatus],
  );

  return (
    <div className="flex flex-col gap-16 md:gap-24 justify-center items-center w-full max-w-[657px]">
      <h1 className="font-display font-bold text-center heading-3 md:heading-4">
        Abra sua conta grátis
      </h1>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <FormFormik
            onSubmit={handleSubmit}
            className="flex flex-col gap-16 md:gap-24 w-full"
            name="form-open-account"
          >
            <div className="flex flex-col gap-16 w-full">
              <TextInput
                name={`fullName${suffix}`}
                label="Nome completo"
                description="Nome do responsável legal"
                maskFunction={dontAllowWhitespaceMask}
                onBlurCallBack={event => handleOnBlur(event, trackOnBlurFields)}
              />
              <TextInput
                name={`email${suffix}`}
                label="E-mail"
                description="Este também será o e-mail de acesso a sua conta"
                onBlurCallBack={event => handleOnBlur(event, trackOnBlurFields)}
              />
              <TextInput
                name={`cnpj${suffix}`}
                label="CNPJ"
                maskFunction={cnpjMask}
                onBlurCallBack={event => handleOnBlur(event, trackOnBlurFields)}
              />
              <TextInput
                name={`cpf${suffix}`}
                label="CPF"
                maskFunction={cpfMask}
                description="CPF do sócio do CNPJ"
                onBlurCallBack={event => handleOnBlur(event, trackOnBlurFields)}
              />
            </div>

            <p className="mx-auto text-center paragraph-14">
              Ao continuar, você concorda com nosso{' '}
              <Link
                href={LINKS.PRIVACY_POLICY}
                className="text-stone-500 cursor-pointer paragraph-14"
                rel="noreferrer"
                target="_blank"
                aria-label="Aviso de Privacidade"
              >
                Aviso de Privacidade
              </Link>
              .
            </p>

            <Button
              type="submit"
              sectionReference="Abra sua conta grátis"
              label="Abrir Conta PJ"
              color="primary"
              size="large"
              disabled={!isValid || !dirty}
              isLoading={isLoading}
              uiStyleVariant="button"
              className="w-full btn-large"
            />
          </FormFormik>
        )}
      </Formik>
    </div>
  );
}
