import { usePathname } from 'next/navigation';

import useAmplitudeExperiment from './useAmplitudeExperiment';

interface HookResponse {
  shouldEnterWebAccountOpeningFlow: boolean;
  shouldEnterWebAccountOpeningFlowControl: boolean;
}

export function useAccountExperiment(): HookResponse {
  const pathname = usePathname() || '/';

  const assignExperimentOnlyOnPJAccountPage = pathname.includes('/conta-pj');

  const { activeVariant } = useAmplitudeExperiment(
    process.env.AMPLITUDE_EXPERIMENT_OPEN_ACCOUNT_WEB,
    assignExperimentOnlyOnPJAccountPage,
  );

  return {
    shouldEnterWebAccountOpeningFlow: activeVariant === 'test',
    shouldEnterWebAccountOpeningFlowControl: activeVariant === 'control',
  };
}
