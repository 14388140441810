export const phoneMask = (v: string): string => {
  let r = v.replace(/\D/g, '');

  if (v.startsWith('+') && v.length > 11) {
    r = r.slice(v.length - 12, v.length);
  }

  r = r.replace(/^0/, '');

  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else if (v.trim() !== '') {
    r = r.replace(/^(\d*)/, '($1');
  }
  return r;
};

export const dontAllowWhitespaceMask = (text: string) => {
  return text.replace(/^\s+|\s{2}$/g, '');
};

export const cpfOrCnpjMask = (v: string) => {
  if (v.length > 18) return v.substring(0, v.length - 1);
  v = v.replace(/\D/g, '');

  if (v.length <= 11) {
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    v = v.replace(/^(\d{2})(\d)/, '$1.$2');
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return v;
};

export const cnpjMask = (v: string) => {
  if (v.length > 18) return v.substring(0, v.length - 1);
  v = v.replace(/\D/g, '');

  v = v.replace(/^(\d{2})(\d)/, '$1.$2');
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
  v = v.replace(/(\d{4})(\d)/, '$1-$2');

  return v;
};

export const cpfMask = (v: string) => {
  v = v.replace(/\D/g, '');

  if (v.length > 11) {
    v = v.substring(0, 11);
  }

  v = v.replace(/(\d{3})(\d)/, '$1.$2');
  v = v.replace(/(\d{3})(\d)/, '$1.$2');
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  return v;
};

export function formatOnlyNumbers(value: string): string {
  return value.replace(/\D/g, '');
}
