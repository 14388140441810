import { isS3 } from '../utils/amazon';

export default function imageLoader({ src, width, quality }) {
  // S3 doesn't support dynamic image transformations
  if (isS3(src)) {
    return src;
  }

  // Demo: https://res.cloudinary.com/demo/image/upload/w_300,c_limit,q_auto/turtles.jpg
  const params = ['f_auto', 'c_limit', `w_${width}`, `q_${quality || 'auto'}`];
  return `https://res.cloudinary.com/dunz5zfpt/${params.join(',')}/${src}`;
}
