'use client';

import { useField } from 'formik';
import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Icon, { IconsNames } from '@/components/v3/Icon';

import styles from './FormikTextInput.module.css';

interface FormikTextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  description?: string;
  name: string;
  rightIcon?: IconsNames;
  maskFunction?: (value: string) => string;
  inputClassName?: CSSProperties;
  onBlurCallBack?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
}

const FormikTextInput: React.FC<FormikTextInputProps> = ({
  label,
  description,
  maskFunction,
  rightIcon,
  onBlurCallBack,
  name,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasAutoFill, setHasAutoFill] = useState(false);
  const [field, meta, helpers] = useField(name);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const activeLabel = useMemo(
    () => hasAutoFill || isFocused || field.value,
    [isFocused, field.value, hasAutoFill],
  );

  useEffect(() => {
    if (inputRef.current) {
      setHasAutoFill(inputRef.current.value !== '');
    }
  }, [field.value]);

  const getInputContainerClassNames = () => {
    return [
      styles.inputContainer,
      rightIcon ? styles.inputContainerHasRightIcon : '',
      isFocused ? styles.inputContainerFocused : '',
      meta?.error && meta?.touched ? styles.hasError : '',
    ]
      .filter(Boolean)
      .join(' ');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = maskFunction
      ? maskFunction(event.target.value)
      : event.target.value;
    helpers.setValue(value);
  };

  const handleInputOnBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    if (onBlurCallBack) onBlurCallBack(event);
    setIsFocused(false);
    field.onBlur(event);
  };

  return (
    <div>
      <div className={getInputContainerClassNames()}>
        <label
          htmlFor={name}
          className={`${styles.label} ${activeLabel ? styles.labelActive : ''}`}
        >
          {label}
        </label>
        <input
          {...field}
          {...props}
          ref={inputRef}
          id={name}
          name={name}
          type={props.type || 'text'}
          className={styles.input}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={handleInputOnBlur}
        />
        {rightIcon && <Icon className={styles.rightIcon} name={rightIcon} />}
      </div>

      {description && (
        <p className="mt-4 text-display-700 paragraph-14">{description}</p>
      )}

      {meta.error && meta.touched && (
        <p id={`${name}-error`} className="mt-4 text-red-500 paragraph-14">
          {meta.error}
        </p>
      )}
    </div>
  );
};

export default FormikTextInput;
