const apiUrl = process.env.MARTECH_API_URL || '';

export interface SignUpDTO {
  fullName: string;
  email: string;
  cnpj: string;
  cpf: string;
}

export const stoneSignUp = async (payload: SignUpDTO): Promise<void> => {
  try {
    const request = new Request(`${apiUrl}/stone-signup`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    });

    const response = await fetch(request);
    if (!response.ok) {
      throw new Error('Failed to sign up');
    }
  } catch (error) {
    console.log(error);
    throw new Error("Couldn't sign up");
  }
};
