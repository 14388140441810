'use client';

import classNames from 'classnames';
import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import ClientOnlyPortal from '@/components/v3/ClientOnlyPortal';
import Icon from '@/components/v3/Icon';
import { AmplitudeContext } from '@/contexts/amplitude';

import { AccountFeedbackStates, Feedback } from '../Feedback';
import Form from '../form';

interface DrawerProps {
  isShowing: boolean;
  onToggle(): void;
}

export default function DrawerAccountOpening({
  isShowing,
  onToggle,
}: DrawerProps) {
  const [status, setStatus] = useState('' as AccountFeedbackStates);
  const modalRef = useRef<HTMLHeadingElement | null>(null);
  const { analytics } = useContext(AmplitudeContext);

  const closeQRCodeModal = useCallback(
    (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      const contain = modalRef.current?.contains(event.target as Node);
      if (!contain) {
        onToggle();
        document.body.removeEventListener('click', () =>
          closeQRCodeModal(event),
        );
      }
    },
    [onToggle],
  );

  useEffect(() => {
    if (isShowing) {
      analytics?.track({
        event_type: 'form viewed',
        event_properties: {
          name: 'form viewed',
          description: `Evento disparado ao abrir o modal mostrando o form`,
          section_reference: 'Abra sua conta grátis',
        },
      });
    }
  }, [analytics, isShowing]);

  return (
    <ClientOnlyPortal selector="#drawer">
      <div
        className={classNames(
          'fixed z-[10000] top-0 overflow-hidden h-screen bg-display-900 bg-opacity-25',
          {
            'w-0': !isShowing,
            'w-screen': isShowing,
          },
        )}
        onClickCapture={(event: MouseEvent<HTMLDivElement>) =>
          closeQRCodeModal(event)
        }
      >
        <div
          className={classNames(
            'flex relative flex-col justify-center items-center p-24 md:p-40 xs:ml-auto w-full max-w-[480px] h-full  bg-display-0 rounded-tl-2xl transition-all',
            {
              'transform-gpu translate-x-[100%]': !isShowing,
              'transform-gpu translate-x-0': isShowing,
            },
          )}
          ref={modalRef}
        >
          <div className="overflow-y-auto min-h-[400px]">
            <button
              type="button"
              className="flex absolute top-24 md:top-40 right-24 md:right-40 justify-center items-center p-8 md:w-40 md:h-40 bg-display-100 rounded-full"
              onClick={onToggle}
              aria-label="close"
            >
              <Icon
                name="close"
                className="w-24 h-24 text-display-600 fill-current"
              />
            </button>
            {status ? (
              <Feedback status={status} setStatus={setStatus} />
            ) : (
              <Form setStatus={setStatus} isDrawer />
            )}
          </div>
        </div>
      </div>
    </ClientOnlyPortal>
  );
}
